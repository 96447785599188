import React from 'react';
import { AppProvider } from "./context/AppContext";

import Login from './components/pages/Login';


export default function App() {
    return (
        <AppProvider>
            <Login />
        </AppProvider>
    );
}
