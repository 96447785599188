const usernamePasswordTextbox = { mt: 2, mb: 2 }

const submitButton = { mt: 2, height: "50px", width: "100%" }

const authBox = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
    width: "90%",
    margin: "0 auto",
    mt: 10,
    p: 2
}

export { usernamePasswordTextbox, submitButton, authBox }