import React, { createContext } from "react";
import axios from "axios";

export const AppContext = createContext();

export const AppProvider = (props) => {
    const healthCheckRandomUrl = [
        "/security/healthcheck",
        "/playground/healthcheck",
        "/healthcheck"
    ];

    const gatewayHealthcheck = async () => {
        return await axios.get(`${process.env.GATEWAY_URL}${healthCheckRandomUrl[Math.floor(Math.random() * healthCheckRandomUrl.length)]}`);
    }

    return (
        <AppContext.Provider
            value={{
                gatewayHealthcheck
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}