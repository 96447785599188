import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext"

import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import LinearProgress from "@mui/material/LinearProgress"

import { usernamePasswordTextbox, submitButton, authBox } from "../../styles/components/pages/Login.style";

export default function Login() {
    const ctx = useContext(AppContext);

    return (
        <>

            <Box>
                <LinearProgress color="secondary" sx={{ width: "100%" }} />
                <Box sx={authBox}>
                    <TextField color="secondary" id="outlined-basic-1" label="Username" variant="outlined" sx={usernamePasswordTextbox} />
                    <TextField color="secondary" id="outlined-basic-2" label="Password" variant="outlined" sx={usernamePasswordTextbox} />
                    <Button color="secondary" variant="contained" sx={submitButton} onClick={async () => console.log(await ctx.gatewayHealthcheck())}>Submit</Button>
                </Box>
            </Box >
        </>
    )
}